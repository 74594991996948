
import promotionBanner from './promotion-banner';

(() => {
  if (typeof $ === 'undefined') {
    throw new TypeError('Zzishjs requires jQuery.');
  }
})();

export default promotionBanner;
