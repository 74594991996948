
/* eslint-disable no-param-reassign */
/* eslint-disable quotes */
import jQuery from 'jquery';
import moment from 'moment';

const promotionBanner = (($, $m) => {
  const urlParams = () => {
    const { search } = window.location;
    return search
      .substring(1)
      .split('&')
      .reduce((result, value) => {
        const parts = value.split('=');
        if (parts[0]) {
          result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
        }
        return result;
      }, {});
  };

  const { coupon, discount, end } = urlParams();

  if (coupon != null && discount != null && end != null) {
    const promoEnd = new Date(end);
    const endDateToRender = $m(promoEnd).format('D MMM');
    const promotionalMessage = `Use coupon ${coupon} to get ${discount}% off when you buy Premium by ${endDateToRender}`;
    if (promoEnd > Date.now()) {
      $('.promotion-banner').text(promotionalMessage)
        .css("display", "flex")
        .hide()
        .fadeIn();
    }
  }
})(jQuery, moment);

export default promotionBanner;
